<template>
    <div>
        <!-- Title and Logo -->
        <h-retribusi />
        <!-- end Logo and Title -->
        <!-- Data Pimpinan -->
        <b-card>
            <div class="pl-4 py-2 border rounded shadow-sm">
                <b-button variant="primary" class="px-4" to="/kepegawaian/pegawai">
                    <i class="fa fa-arrow-circle-left pr-2"></i> 
                    Kembali
                </b-button>
            </div>
            <div class="py-2" v-if="pegawai">
                <b-table-simple bordered small responsive>
                    <b-th colspan="5">
                        <h5 class="text-center">Detail Pegawai</h5>
                    </b-th>
                    <b-tr>
                        <b-td width="150px">Nama</b-td>
                        <b-td width="300px" class="font-weight-bold">{{ pegawai.nama }}</b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>NIK</b-td>
                        <b-td colspan="4" class="font-weight-bold">{{ pegawai.nik }}</b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>NIP</b-td>
                        <b-td colspan="4" class="font-weight-bold">{{ pegawai.nip}}</b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>Tempat dan Tanggal Lahir</b-td>
                        <b-td colspan="4">
                            {{ pegawai.tmp_lahir }}, {{ pegawai.tgl_lahir }}
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>Jenis Kelamin</b-td>
                        <b-td colspan="4">{{ pegawai.jenis_kelamin }}</b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>Alamat</b-td>
                        <b-td colspan="4">
                            {{ pegawai.alamat }} <br>
                            Kel. {{ pegawai.wilayah_kelurahan.nama }} <br>
                            Kec. {{ pegawai.wilayah_kelurahan.wilayah_kecamatan.nama }} <br>
                            {{ pegawai.wilayah_kelurahan.wilayah_kecamatan.wilayah_kabkota.nama }} <br>
                            {{ pegawai.wilayah_kelurahan.wilayah_kecamatan.wilayah_kabkota.wilayah_provinsi.nama }} <br>
                            {{ pegawai.kodepos }}
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>No. Handphone</b-td>
                        <b-td colspan="4">
                            {{ pegawai.nomp }}
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>Email</b-td>
                        <b-td colspan="4">{{ pegawai.email }}</b-td>
                    </b-tr>
                </b-table-simple>
            </div>
        </b-card>
        <!-- End Data Pimpinan -->
    </div>
</template>

<script>
/* eslint-disable */
// eslint-disable-next-line
// eslint-disable vue/no-unused-vars
// eslint-disable-line no-unused-vars
import axios from 'axios'
import HRetribusi from '@/components/header/HRetribusi.vue'
import dateMixin from '@/helper/dateFormat.js'
import storeMixin from '@/helper/hapusLocalStore.js'

export default {
    mixins: [dateMixin, storeMixin],
  components: { HRetribusi },
    data () {
        return {
            // variable form
            form: new Form({
                pegawai_id: this.$route.params.id,
                jabatan_id: '',
                status_jabatan: '',
                tmt_jabatan: '',
                no_sk: '',
                tgl_sk: '',
                status_sk: '',
                dok_sk: ''
            }),
            pegawai: null,
            isBusy     : false,
            sortBy     : null,
            sortDesc   : false,
            filter     : null,
            filterBy   : "all",
            pageOptions: [5, 10, 15, 25],
            perPage    : 10,
            currentPage: 1,
            totalRows  : 0,
            errors: [],

            fields: [
                {
                    key  : "index",
                    label: 'No.'
                },
                {
                    key     : "no_sk",
                    label   : 'Nomor SK',
                    // sortable: true
                },
                {
                    key     : "jabatan",
                    label   : 'Jabatan',
                    // sortable: true
                },
                {
                    key     : "status_jabatan",
                    label   : 'Status',
                    // sortable: true
                },
                {
                    key     : "tmt_jabatan",
                    label   : 'TMT',
                    // sortable: true
                },
                {
                    key     : "tgl_sk",
                    label   : 'Tanggal SK',
                    // sortable: true
                },
                {
                    key     : "status_sk",
                    label   : 'Aktif',
                    // sortable: true
                },
                {
                    key  : "actions",
                    label: 'Opsi'
                }
            ],
            // jabatan vue select
            jabatanOptions: [],
            showModalForm: false,
            // datepicker option
            options: {
				format: 'DD MMMM YYYY',
                useCurrent: true,
            },
            editMode: false,
            riwayat_id: null,
            isDisabled: false
        }
    },
    computed: {
        fieldOptions() {
        // Create an options list from our fields
        return this.fields
            .filter((f) => f.sortable)
            .map((f) => {
            return {
                text : f.label,
                value: f.key,
            };
            });
        },
    },
    mounted () {
        axios.get('/api/data-induk/kepegawaian/pegawai/' + this.$route.params.id).then(response => {
            const item = response.data.data
            this.pegawai = item
            // date format mixin
            this.pegawai.tgl_lahir = this.localString(this.pegawai.tgl_lahir) 
        }).catch(error => {
            if (error.response.status === 401) {
                // helper hapus local storage
                this.clearAll()
            }
        })
        this.getJabatan()
    },
    methods: {
        myGetData(ctx) {
        // ctx =
        // berasal dari tag <b-table></b-table>
        //this.$Progress.start();
        this.isBusy = true;
        let promise = axios.get("/api/data-induk/kepegawaian/pegawai/" + this.$route.params.id + "/jabatan", {
            params: {
            page    : ctx.currentPage,
            perpage : ctx.perPage,
            sortby  : ctx.sortBy,
            sortdesc: ctx.sortDesc,
            filter  : ctx.filter,
            filterby: this.filterBy,
            },
        });
        return promise
            .then((response) => {
            const items = response.data.data;
            // Data Pagination
            // configPagination(response.data.meta);
            this.totalRows = response.data.meta.total;
            // Here we could override the busy state, setting isBusy to false
            //this.$Progress.finish();
            this.isBusy = false;
            return items;
            })
            .catch((error) => {
            // Here we could override the busy state, setting isBusy to false
            //this.$Progress.fail();
            this.isBusy = false;
            if (error.response.status === 401) {
					// helper hapus local storage
                    this.clearAll()
				}
            // Returning an empty array, allows table to correctly handle
            // internal busy state in case of error
            return [];
            });
        },

        swalNotification(swalType, id) {
            var btnClass = (swalType == 'error') ? 'danger' : swalType;
                btnClass = (swalType == 'question') ? 'primary' : btnClass;
            this.$swal({
                title             : 'Anda yakin ?',
                text              : 'Anda tidak akan bisa mengembalikan data!',
                type              : swalType,
                showCancelButton  : true,
                buttonsStyling    : false,
                confirmButtonText : "Hapus",
                cancelButtonText  : 'Cancel',
                confirmButtonClass: 'btn m-r-5 btn-'+ btnClass +'',
                cancelButtonClass : 'btn btn-default',
            }).then(result => {
                if (result.isConfirmed) {
                    axios.delete('/api/data-induk/pegawai/' + this.$route.params.id + '/jabatan/' + id).then(response => {
                        this.$swal({
                            title: "Data Berhasil Dihapus!",
                            icon: 'success',
                            type: 'success',
                            showCloseButton: true,
                            showConfirmButton: false,
                            timer: 1800
                        })
                        this.reload()
                    }).catch(error => {
                        if (error.response.status === 401) {
                            // helper hapus local storage
                            this.clearAll()
                        }
                    })
                }
            })
        },
        editButton (index) {
            this.$router.push({name: 'EditPegawai', params:{id: index}})
        },
        // store data
        store () {
            this.isDisabled = true
            this.form.tgl_sk = this.backEndDateFormat(this.form.tgl_sk)
            this.form.tmt_jabatan = this.backEndDateFormat(this.form.tmt_jabatan)
            this.form.post('/api/data-induk/pegawai/' + this.$route.params.id + "/jabatan").then(response => {
                if (this.form.successful) {
                    this.isDisabled = false
                    this.$swal({
                        icon: 'success',
                        title: 'Data berhasil ditambah',
                        type: 'success',
                        confirmButtonClass: 'btn btn-info',
                        confirmButtonText: 'OKE',
                        showConfirmButton: true,
                        showCloseButton: true,
                        timer: 5000
                    })
                    
                   this.form.reset()
                   this.reload() 
                }
            }).catch(error => {
                this.isDisabled = false
                if (error.response.status === 422) {
                    this.errors = error.response.data.errors
                    this.message = error.response.data.message
                } else if (error.response.status === 401) {
					// helper hapus local storage
                    this.clearAll()
				} else {
                    this.$swal({
                        title             : 'Tejadi Masalah',
                        icon              : 'error',
                        showCloseButton   : true,
                        buttonsStyling    : false,
                        showConfirmButton : true,
                        confirmButtonText : "OKE",
                        confirmButtonClass: "btn btn-primary",
                        timer             : 5000
                    })
                }
            })
        },

        // update data
        update (id) {
            this.isDisabled = true
            this.form.tgl_sk = this.backEndDateFormat(this.form.tgl_sk)
            this.form.tmt_jabatan = this.backEndDateFormat(this.form.tmt_jabatan)
            this.form.put('/api/data-induk/pegawai/' + this.$route.params.id + "/jabatan/" + id).then(response => {
                if (this.form.successful) {
                    this.isDisabled = false
                    this.$swal({
                        icon: 'success',
                        title: 'Data berhasil diupdate',
                        type: 'success',
                        confirmButtonClass: 'btn btn-info',
                        confirmButtonText: 'OKE',
                        showConfirmButton: true,
                        showCloseButton: true,
                        timer: 5000
                    })
                    
                   this.resetForm()
                   this.editMode = false
                   this.showModalForm = false
                   this.reload() 
                }
            }).catch(error => {
                this.isDisabled = false
                if (error.response.status === 422) {
                    this.errors = error.response.data.errors
                    this.message = error.response.data.message
                } else if (error.response.status === 401) {
					// helper hapus local storage
                    this.clearAll()
				} else {
                    this.$swal({
                        title             : 'Tejadi Masalah',
                        icon              : 'error',
                        showCloseButton   : true,
                        buttonsStyling    : false,
                        showConfirmButton : true,
                        confirmButtonText : "OKE",
                        confirmButtonClass: "btn btn-primary",
                        timer             : 5000
                    })
                }
            })
        },
        // reload
        reload() {
        // with id="masTable" in tag b-table
        // this.$root.$emit('bv::refresh::table', 'masTable')
        // with property ref="masTable" in tag b-table
        this.$refs.masTable.refresh();
        },

        // data jabatan
        getJabatan () {
            axios.get('/api/data-induk/kepegawaian/jabatan/getoption')
            .then(response => {
                this.jabatanOptions = response.data.data
            })
            .catch(error => {
                if (error.response.status === 401) {
					// helper hapus local storage
                    this.clearAll()
				}
            })
        },

        // data detail riwayat jabatan 
        getRiwayatJabatan (id) {
            axios.get('/api/data-induk/pegawai/' + this.$route.params.id + '/jabatan/' + id).then(response => {
                const item = response.data.data
                this.form.fill(item)
                this.form.tgl_sk = this.frontEndDateFormat(this.form.tgl_sk)
            }).catch(error => {
                if (error.response.status === 401) {
					// helper hapus local storage
                    this.clearAll()
				}
            })
        },

        // ---
        resetForm() {
            this.form.reset();
            this.form.clear();
            this.errors = [];
            this.message = "";
        },
        create() {
            this.editMode = false
            this.resetForm();
            this.$root.$emit("bv::show::modal", 'modal');
            this.showModalForm == true;
        },
        edit (id) {
            this.editMode = true
            this.resetForm()
            this.riwayat_id = id
            this.getRiwayatJabatan(id)
            this.$root.$emit("bv::show::modal", 'modal');
            this.showModalForm == true;
        },

         // method helper
        // frontEndDateFormat: function (date) {
        //     if (date) {
        //         return moment(date, 'DD MMM YYYY').format('YYYY-MM-DD')
        //     } else {
        //         return null;
        //     }
        // },
        // backEndDateFormat: function (date) {
        //     if (date) {
        //         return moment(date, 'DD MMMM YYYY').format('YYYY-MM-DD')
        //         // return new Date(date)
        //     } else {
        //         return null;
        //     }
        // },
    }
}
</script>